<template>
  <div v-if="av" class="wrapper">
    <p>
      <v-icon
        v-if="av.case !== 'outofstock'"
        size="large"
        :color="av.color"
        class="icon"
        >mdi-checkbox-marked-circle-outline
      </v-icon>
      <v-icon v-else size="large" :color="av.color" class="icon"
        >mdi-close-circle </v-icon
      >{{ av.message }}
    </p>

    <p v-if="av.case !== 'outofstock'">
      <v-icon size="large" :color="av.color" class="icon"
        >mdi-truck-delivery</v-icon
      >{{ av.days }}
      <v-icon
        v-if="av.badge"
        size="large"
        color="red"
        class="icon ml-3 pa-35"
        @click="badge = !badge"
        @mouseenter="badge = true"
        @mouseleave="badge = false"
        >mdi-help-circle-outline</v-icon
      >
    </p>
    <p v-if="badge" v-html="av.badgeText" class="badge"></p>
  </div>
</template>

<script>
export default {
  name: "Availability",
  props: {
    av: Object
  },
  data: function() {
    return {
      badge: false
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  padding: 10px;
  p {
    text-align: left;
    margin: 4px 0;
    font-size: 14.5px;
    display: flex;
    align-items: center;
  }
}
.icon {
  margin-right: 10px;
}

.badge {
  font-size: 13px !important;
  max-width: 100%;
  font-weight: 400;
  white-space: wrap;
  background-color: rgb(243, 243, 243);
  padding: 10px;
  line-height: 20px;
}
</style>
