<template>
  <div class="meta-footer-wrapper">
    <v-footer height="auto" dark color="#2f2f2f">
      <b-container>
        <div class="footer-wrapper" v-if="i18n.locale === 'en'">
          <div class="sub-element1">
            <ul>
              <li v-for="link in links1" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element2">
            <ul>
              <li v-for="link in links2" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element3">
            <ul>
              <li v-for="link in links3" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item" @click="() => setCookiesManager(true)">
                <span class="link">Cookies Manager</span>
              </li>
            </ul>
          </div>
          <div class="sub-element4">
            <ul>
              <li v-for="link in links4" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item">
                <a
                  target="_blank"
                  href="https://www.facebook.com/ozperformance/"
                  >Facebook</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-wrapper" v-else-if="i18n.locale === 'de'">
          <div class="sub-element1">
            <ul>
              <li v-for="link in links1de" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element2">
            <ul>
              <li v-for="link in links2de" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element3">
            <ul>
              <li v-for="link in links3de" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item" @click="() => setCookiesManager(true)">
                <span class="link">Cookie-Auswahl</span>
              </li>
            </ul>
          </div>
          <div class="sub-element4">
            <ul>
              <li v-for="link in links4de" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item">
                <a
                  target="_blank"
                  href="https://www.facebook.com/ozperformance/"
                  >Facebook</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-wrapper" v-else-if="i18n.locale === 'pl'">
          <div class="sub-element1">
            <ul>
              <li v-for="link in links1pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element2">
            <ul>
              <li v-for="link in links2pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sub-element3">
            <ul>
              <li v-for="link in links3pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
              <li class="link-item" @click="() => setCookiesManager(true)">
                <span class="link">Zarządzaj ustawieniami cookies</span>
              </li>
            </ul>
          </div>
          <div class="sub-element4">
            <ul>
              <li v-for="link in links4pl" :key="link.name" class="link-item">
                <router-link :to="link.to">{{ link.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </b-container>
    </v-footer>
    <div class="footer-logo">
      <router-link to="/">
        <picture>
          <source
            type="image/webp"
            srcset="./../assets/Ozperformance_logo.webp"
          />
          <img src="./../assets/Ozperformance_logo.png" alt="Ozparts" />
        </picture>
      </router-link>
      <div class="copy my-container">
        Ozperformance © 2020 All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import i18n from "./../i18n";

export default {
  name: "FooterComponent",
  data: () => {
    return {
      i18n,
      links1: [
        {
          name: "About Us",
          to: "/about"
        },
        {
          name: "Catalogues",
          to: "/catalogue"
        },
        {
          name: "News",
          to: "/news"
        }
      ],
      links1de: [
        {
          name: "Über uns",
          to: "/about"
        },
        {
          name: "Kataloge",
          to: "/catalogue"
        },
        {
          name: "Nachrichten",
          to: "/news"
        }
      ],
      links1pl: [
        {
          name: "O nas",
          to: "/about"
        },
        {
          name: "Katalog",
          to: "/catalogue"
        },
        {
          name: "Panel Użytkownika",
          to: "/panel"
        },
        {
          name: "Aktualności",
          to: "/news"
        }
      ],
      links2: [
        {
          name: "Returns",
          to: "/faq#return"
        },
        {
          name: "Payment Methods",
          to: "/faq#payment"
        },
        {
          name: "Warranties",
          to: "/faq#warranties"
        },
        {
          name: "Shipping Options",
          to: "/faq#shipping"
        }
      ],
      links2de: [
        {
          name: "Retouren",
          to: "/faq#return"
        },
        {
          name: "Zahlungsmethoden",
          to: "/faq#payment"
        },
        {
          name: "Gewährleistungen",
          to: "/faq#warranties"
        },
        {
          name: "Versandoptionen",
          to: "/faq#shipping"
        }
      ],
      links2pl: [
        {
          name: "Zwroty",
          to: "/faq#return"
        },
        {
          name: "Metody płatności",
          to: "/faq#payment"
        },
        {
          name: "Gwarancje",
          to: "/faq#warranties"
        },
        {
          name: "Metody dostawy",
          to: "/faq#shipping"
        }
      ],
      links3: [
        {
          name: "Privacy Policy",
          to: "/privacy"
        },
        {
          name: "Cookies Policy",
          to: "/cookies-policy"
        },
        {
          name: "Terms & Conditions",
          to: "/terms"
        }
      ],
      links3de: [
        {
          name: "Datenschutzrichtlinie",
          to: "/privacy"
        },
        {
          name: "Cookie-Richtlinie",
          to: "/cookies-policy"
        },
        {
          name: "AGB",
          to: "/terms"
        }
      ],
      links3pl: [
        {
          name: "Polityka prywatności",
          to: "/privacy"
        },
        {
          name: "Polityka Cookies",
          to: "/cookies-policy"
        },
        {
          name: "Warunki i zobowiązania",
          to: "/terms"
        }
      ],
      links4: [
        {
          name: "Help / FAQ",
          to: "/faq"
        },
        {
          name: "Contact",
          to: "/contact"
        }
      ],
      links4de: [
        {
          name: "Hilfe / FAQ",
          to: "/faq"
        },
        {
          name: "Kontakt",
          to: "/contact"
        }
      ],
      links4pl: [
        {
          name: "Pomoc / FAQ",
          to: "/faq"
        },
        {
          name: "Kontakt",
          to: "/contact"
        }
      ]
    };
  },
  methods: {
    ...mapMutations(["setCookiesManager"])
  }
};
</script>

<style lang="scss">
.meta-footer-wrapper {
  .link-item {
    a {
      color: white;
    }
  }

  .footer-wrapper {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 3em 0;
    line-height: 2em;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1em;
    align-items: flex-start;
    grid-template-areas:
      "el1 el1 el2 el2"
      "el3 el3 el4 el4";

    .sub-element1 {
      grid-area: el1;
    }

    .sub-element2 {
      grid-area: el2;
    }

    .sub-element3 {
      grid-area: el3;
    }

    .sub-element4 {
      grid-area: el4;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    @media screen and (min-width: 599px) {
      grid-template-areas: "el1 el2 el3 el4";
    }
  }
  .footer-logo {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;

    img {
      width: 300px;
    }
  }

  .copy {
    padding-left: 20px;
    margin-top: 10px;
    font-size: smaller;
  }
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
