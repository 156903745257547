<template>
  <div>
    <div class="basket-item-wrapper">
      <item-photo :object="object" />
      <div class="details">
        <div class="price">
          <item-name :object="object" />
          <item-price :object="object" :symbol="symbol" />
        </div>
        <div class="qty">
          <b-input-group v-if="object.item.urlcomponent" class="group">
            <div class="prepend">{{ $t("COMMON.QTY") }}</div>
            <b-form-input
              v-if="this.item"
              v-model="object.quantity"
              type="number"
              min="1"
              :max="max"
              :disabled="isLoading || summary || additionalService"
              @change="e => updateQuantity(e)"
            />
          </b-input-group>
        </div>

        <div>
          <toggle-components-btn
            v-if="kitComponents.length"
            mobile
            :show-components="showComponents"
            @toggleComponents="toggleComponents"
          />
          <b-btn
            v-if="object.item.urlcomponent && !summary"
            :disabled="isLoading"
            class="button"
            @click="remove"
            >{{ $t("COMMON.REMOVE") }}</b-btn
          >
        </div>
        <div class="additional-services bottom mt-2">
          <div
            v-for="(service, index) in object.additionalservice"
            :key="index"
          >
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
      <toggle-components-btn
        v-if="kitComponents.length"
        :show-components="showComponents"
        @toggleComponents="toggleComponents"
      />
    </div>
    <kit-component-list v-if="showComponents" :kit-components="kitComponents" />
    <hr class="my-4" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios3C from "../../axios";
import ItemPhoto from "./ItemPhoto";
import ItemName from "./ItemName";
import ItemPrice from "./ItemPrice";
import ToggleComponentsBtn from "./toggleComponentsBtn.vue";
import KitComponentList from "./KitComponentList.vue";
import api from "@/api";

export default {
  name: "BasketItemComponent",
  components: {
    ItemPhoto,
    ItemName,
    ItemPrice,
    ToggleComponentsBtn,
    KitComponentList
  },
  mixins: [api],

  props: {
    summary: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      required: true
    },
    cartId: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    kitComponents: {
      type: Array,
      default: () => []
    }
  },
  async created() {
    const item = await this.getItem(this.object.item.urlcomponent);
    this.item = item.data;
  },
  computed: {
    ...mapGetters(["getBasketCount"]),
    max() {
      if (this.item.name) {
        const object = this.item.available.find(
          loc => loc.location === this.location
        );
        return object.specialbackorder
          ? this.warehouseAvl + this.manufacturerAvl
          : this.warehouseAvl;
      }
      return "";
    },
    location() {
      const defaultStock = this.item.available.find(e => e.default);
      return defaultStock.location;
    },
    additionalService() {
      return !!this.object.additionalservice.length;
    },

    warehouseAvl() {
      const object = this.item.available.find(
        loc => loc.location === this.location
      );
      return object.quantityavailable || 0;
    },
    manufacturerAvl() {
      const obj = this.item.available.find(
        loc => loc.location === "manufacturer"
      );
      return obj.quantityavailable || 0;
    }
  },
  data() {
    return {
      isLoading: false,
      axios3C,
      showComponents: false,
      previousItemQuantity: this.object.quantity,

      item: {}
    };
  },
  methods: {
    toggleComponents() {
      this.showComponents = !this.showComponents;
    },
    updateQuantity() {
      this.isLoading = true;
      this.$emit("deletingStart");
      this.axios3C
        .post("/cart", {
          action: "updateCart",
          cart_id: this.cartId,
          line_id: this.object._id,
          field: "quantity",
          value: this.object.quantity,
          customer: "guest"
        })
        .then(res => {
          this.$emit("delete", res.data);
          this.$store.commit(
            "updateQuantity",
            Number(this.getBasketCount) -
              this.previousItemQuantity +
              Number(this.object.quantity)
          );
          this.previousItemQuantity = Number(this.object.quantity);
        })
        .catch(err => {
          this.$emit("error", err);
        })
        .finally(() => {
          this.$emit("deletingStop");
          this.isLoading = false;
        });
    },
    remove() {
      this.isLoading = true;
      this.$emit("deletingStart");
      this.axios3C
        .post("/cart", {
          action: "updateCart",
          cart_id: this.cartId,
          line_id: this.object._id,
          field: "deleted",
          value: true,
          customer: "guest"
        })
        .then(res => {
          this.$emit("delete", res.data);
          this.$store.commit(
            "updateQuantity",
            Number(this.getBasketCount) - Number(this.object.quantity)
          );
        })
        .catch(err => {
          this.$emit("error", err);
        })
        .finally(() => {
          this.$emit("deletingStop");
          this.isLoading = false;
        });
    }
  },
  watch: {
    "object.quantity": function() {
      if (this.object.quantity > this.max) {
        this.object.quantity = this.max;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.basket-item-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-column-gap: 20px;

  @media screen and (max-width: 960px) {
    grid-template-columns: auto;
    text-align: center;
  }

  .additional-services {
    color: #707070;
    width: 65%;
  }

  .details {
    display: grid;
    grid-template-areas:
      "price qty button"
      "bottom bottom button";
    grid-column-gap: 10px;

    @media screen and (max-width: 960px) {
      grid-template-areas:
        "price"
        "qty"
        "button"
        "bottom";
      text-align: center;
    }

    .price {
      grid-area: price;
      margin-top: 0;
      @media screen and (max-width: 960px) {
        margin-top: 12px;
      }
    }

    .qty {
      grid-area: qty;
    }

    .button {
      grid-area: button;
    }

    @media screen and (max-width: 960px) {
      .button {
        width: 100%;
      }
    }

    .bottom {
      grid-area: bottom;
    }

    .deliver-group {
      margin-top: 20px;
      font-size: 0.8em;
    }

    .group {
      width: 200px;

      @media screen and (max-width: 960px) {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }

    input {
      height: 40px;
      width: 100px;
    }

    .prepend {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 0 20px;
      background-color: #e9ecef;
      border-top: 1px solid #ced4da;
      border-left: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
    }
  }

  .remove {
    text-align: right;

    @media screen and (max-width: 960px) {
      text-align: center;
    }
    .button {
      background-color: black;
      text-transform: uppercase;
    }
  }
}
</style>

<style lang="scss">
.air-freight {
  label {
    line-height: 20px;
  }
}
</style>
