const SOURCE = "ozperformanceeu";

let CURRENCY = "eur";
let TAX = "5e0dbaba9e33df43f0b3a47f"; // 23%
let TAX_FLOAT = 1.23;
let URL_BASE = "https://ozperformance.eu";

export const PAYMENT_METHODS = {
  REVOLUT: "revolut",
  PAYPAL: "607468324e5b797a767fe87d"
};

export const WIDGET_TYPE = {
  REVOLUT: "Revolut",
  PAYPAL: "PayPal"
};

if (SOURCE === "ozperformanceuk") {
  CURRENCY = "gbp";
  TAX = "5e22ee60f4e3f79cece0f938"; // 20%
  TAX_FLOAT = 1.2;
  URL_BASE = "https://ozperformance.co.uk";
}

export default {
  SOURCE,
  CURRENCY,
  TAX,
  TAX_FLOAT,
  URL_BASE
};
